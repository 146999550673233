<template>
  <div class="bg">
    <div class="flex">
      <div class="left">
        <div class="box" :class="classObject" :style="style">
          <div class="code">{{ companyNode }}</div>
          <div class="qr-code"><img :src="qrCode"></div>
        </div>
      </div>
      <div class="right">
        <span>身份标签</span>
        <div class="item" :class="{active:styleKey==0}" @click="changeStyle(0)">身份标签样式一</div>
        <div class="item" :class="{active:styleKey==1}" @click="changeStyle(1)">身份标签样式二</div>
        <div class="item" :class="{active:styleKey==2}" @click="changeStyle(2)">身份标签样式三</div>
        <div class="item" :class="{active:styleKey==3}" @click="changeStyle(3)">身份标签样式四</div>
        <div class="download" @click="download">下载标签</div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyApi } from "@/services/api";
import config from "@/config/config";
import { downloadIamge } from "@/utils/downloadImage";
export default {
  data() {
    return {
      currentStyle: 0,
      styleList: [
        "https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/d21daa3d-909d-4db0-9ebe-fd2f2428cc0e.png",
        "https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/23372f47-e14b-4371-a389-891d61aafa9a.png",
        "https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/20d63877-9253-44a0-956c-d2ee2674bfed.png",
        "https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/0b979c94-7b4f-4ced-8c60-5724fbdf473d.png"
      ],
      style: {
        backgroundImage: "url('https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/d21daa3d-909d-4db0-9ebe-fd2f2428cc0e.png')"
      },
      styleKey: 0,
      qrCode: ""
    };
  },
  computed: {
    classObject() {
      return `style-${this.styleKey}`;
    },
    companyNode() {
      return this.$store.state.company_node;
    }
  },
  mounted() {
    this.getQrCode();
  },
  methods: {
    changeStyle(key) {
      this.styleKey = key;
      this.style.backgroundImage = `url('${this.styleList[key]}')`;
    },
    async getQrCode() {
      let { data } = await companyApi.getQrCode({ content: `${config.webSiteHttp}record?nodeId=${this.companyNode}` });
      this.qrCode = data;
    },
    async download() {
      let { data } = await companyApi.downloadImag({ type: `style-${this.styleKey + 1}` });
      downloadIamge(data, `身份标签-样式${this.styleKey + 1}`);
    }
  }
};
</script>

<style lang="less" scoped>
.bg{
  background-color: #FFFFFF;
  padding: 43px  0 177px 33px;
}
.flex{
  display: flex;
}
.left{
  width: 380px;
  margin-right: 37px;
  .box{
    width: 100%;
    height: 538px;
    background-size: cover;
    position: relative;
    .code{
      color: #77572F;
      text-align: center;
      padding-top: 201px;
      font-size: 11px;
    }
    .qr-code{
      padding-top: 130px;
      text-align: center;
      img{
        width: 130px;
        height: 130px;
        margin-left: -12px;
      }
    }
  }
  .box.style-1{
     .code{
       padding-top: 193px;
     }
     .qr-code{
       padding-top: 158px;
     }
  }
  .box.style-2{
     .code{
       padding-top: 194px;
     }
     .qr-code{
       padding-top: 141px;
     }
  }
  .box.style-3{
     .code{
       padding-top: 180px;
     }
     .qr-code{
       padding-top: 147px;
     }
  }
}
.right{
  color: #011539;
  font-size: 14px;
  span{
    margin-bottom: 27px;
    display: block;
  }
  .item{
    margin-bottom: 20px;
    width: 168px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    opacity: 1;
    border-radius: 2px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
  .item:hover{
    background-color: #F5F5F5;
  }
  .active,
  .item.active:hover{
    color: #1285D6;
    border-color: #1285D6;
    background-color: #E9F6FF;
  }
  .download{
    width: 96px;
    height: 36px;
    background: #1285D6;
    opacity: 1;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
  }
}
</style>